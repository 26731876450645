<template>
  <div class="join-container">
    <img src="../assets/img/加入我们.png" />
    <div class="divider">
      <p class="shadow-text">JOIN US AND YOU WILL GET</p>
      <p>加入我们，你将会获得...</p>
    </div>
    <div class="swiper">
      <div class="item">
        <div class="content">
          <p>与行业顶尖团队共同成长</p>
          艺龙酒店科技汇集来自行业内TOP5实力精英,共同创建住宿行业美好未来.除此以外,我们提供职业发展、指导与培训机会,助你实现梦寐以求的职业目标。
        </div>
      </div>
      <div class="item">
        <div class="content">
          <p>具有行业竞争力了的薪酬</p>
          公司认可员工付出的成就和贡献,为我们员工获得具有竞争力的薪酬和福利,不仅如此,我们还会对人才荐举与长期任职而给子额外奖励。<br />
        </div>
      </div>
      <div class="item">
        <div class="content">
          <p>广阔的发展平台</p>
          艺龙酒店科技涵盖八大酒管公司、六大住宿赋能平台与艺龙会等住宿产业公司,工作岗位广泛丰富,员工有机会挑战不同的岗位,培养并发展自己的技能与经验。
        </div>
      </div>
    </div>
    <div class="list-container">
      <p class="title">最新工作机会</p>
      <ul class="list">
        <li class="list-item" v-for="item in list" :key="item.id">
          <div class="item-top">
            <p class="name">{{ item.postName }}</p>
            <van-button class="btn" @click="goDetail(item.id)"
              >点我查看</van-button
            >
          </div>
          <p class="gery">
            发布时间 :
            <span style="margin-left: 4rem">{{
              getDate(item.createTime)
            }}</span>
          </p>
          <p class="gery">
            <span class="ellipsis">{{ item.postBase }}</span>
            <span class="ellipsis">{{ item.workingCompany }}</span>
            <span class="ellipsis">{{ item.salary }}</span>
          </p>
        </li>
      </ul>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import { getJobList } from "@/api/index.js";
import { CHANNEL, CHANNELCODE, RECOMMEND_Id } from "@/utils/constants";
import { setSessionStorage } from "@/utils/storage";
import { trackEvent } from "@/utils/tracklog.js";

export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getData() {
      getJobList({ equalRecruitChannel: CHANNELCODE[this.channel] }).then(
        (res) => {
          this.list = res.rows || [];
        }
      );
    },
    goDetail(id) {
      this.$router.push({
        path: `/joinUs/detail/${id}`,
      });
    },
    getDate(timeStr) {
      return timeStr && timeStr.length > 10 ? timeStr.substring(0, 10) : "-";
    },
  },
  mounted() {
    this.getData();
    setSessionStorage(CHANNEL, this.$route.query.channel || "official");
    setSessionStorage(RECOMMEND_Id, this.$route.query.recommendId || "");
     //埋点 加入我们
     trackEvent(`加入我们`, "浏览", `加入我们`, {}, 0, new Date().getTime());
  },
  computed: {
    // 页面来源
    channel() {
      return this.$route.query.channel || "official";
    },
  },
};
</script>
<style lang="scss" scoped>
.join-container {
  & > img {
    width: 100%;
    max-width: 100%;
  }

  .divider {
    text-align: center;
    font-size: 30rem;
    padding: 40rem 0;
    position: relative;

    & > .shadow-text {
      color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 24%;
      left: 22%;
    }
  }

  .swiper {
    width: 100%;
    overflow: auto;
    display: flex;

    & > .item {
      min-width: 60%;
      height: 360rem;
      color: #fff;
      position: relative;

      background: {
        repeat: no-repeat;
        image: url(../assets/img/join_bg.png);
        size: 100% 100%;
      }
    }

    .content {
      position: absolute;
      bottom: 8rem;
      left: 8rem;
      right: 8rem;
      font-size: 22rem;
      vertical-align: top;
      line-height: 1.2em;

      & > p {
        font-size: 24rem;
        margin-bottom: 16rem;
      }
    }

    .item + .item {
      margin-left: 2%;
    }
  }

  .list-container {
    padding: 0 30rem;
    margin: 40rem 0;

    & > .title {
      font-size: 30rem;
      margin-bottom: 24rem;
    }

    .list-item {
      font-size: 26rem;
      padding: 24rem 0;
      border-bottom: 1px solid #eee;

      & > .item-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .name {
        font-weight: bold;
      }

      .btn {
        background-color: #005250;
        color: #fff;
        height: 56rem;
        line-height: 56rem;
        font-size: 24rem;
      }

      .gery {
        color: #999;
        margin-top: 18rem;
      }

      .ellipsis {
        max-width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        & + .ellipsis {
          margin-left: 2.5%;
        }
      }
    }
  }

  .footer {
    position: relative;
    background: url("../assets/img/footer.jpg") no-repeat;
    background-size: 100% 100%;
    height: 0;
    padding-bottom: 41%;
    color: #fff;
  }
}
</style>
